@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 35px;
  border: 1px solid var(--colorGrey100);
  padding: 0 8px;

  background-color: var(--colorWhite);
  color: unset;

  transition: all var(--transitionStyleButton);
  cursor: pointer;

  font-weight: var(--fontWeightSemiBold);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-decoration: none;

  & + .link {
    margin-left: -1px;
  }

  &:first-of-type {
    border-top-left-radius: var(--borderRadiusMedium);
    border-bottom-left-radius: var(--borderRadiusMedium);
  }

  &:last-of-type {
    border-top-right-radius: var(--borderRadiusMedium);
    border-bottom-right-radius: var(--borderRadiusMedium);
  }
}

.linkActive {
  z-index: 1;

  border: 1px solid var(--marketplaceColor);

  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}
